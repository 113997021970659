import Vue from "vue";

Vue.mixin({
  methods: {
    $formatDate(post) {
      if (!post) return null;

      return new Date(post.published_at || post.created_at).toLocaleDateString(
        this.$i18n.locale,
        {
          year: "numeric",
          month: "long",
          day: "numeric",
        }
      );
    },

    // Check NEW tag
    $checkNewTag(product) {
      if (product.tags) {
        for (let i = 0; i < product.tags.length; i++) {
          if (product.tags[i].name === "NOVO") {
            return true;
          }
        }
      }
    },

    $returnDiscountPercent(product) {
      /* eslint-disable */
      if (product.hasOwnProperty("discount")) {
        const oldPrice = product.retailPrice;
        const newPrice = product.discount.retailPrice;
        const calculatePrice = (oldPrice - newPrice) / oldPrice;
        const calculatePercent = calculatePrice * 100;
        return calculatePercent.toFixed();
      } else return false;
      /* eslint-enable */
    },
    $checkTag(product, tag) {
      if (product.tags) {
        for (let i = 0; i < product.tags.length; i++) {
          if (product.tags[i].name === tag) {
            return true;
          }
        }
      }
    },
    // Check NEW tag
    $checkTopTag(product) {
      if (product.tags) {
        for (let i = 0; i < product.tags.length; i++) {
          if (product.tags[i].name === "NAJPRODAVANIJE") {
            return true;
          }
        }
      }
    },

    $shareUrl() {
      if (process.browser) {
        return window.location.href;
      }
    },
    async $getCustomPost(categories, type, limit, page) {
      try {
        const filters = [{ key: "display", value: "components" }];
        let defaultLimit = 500;
        let currentPage = 1;
        if (page) {
          currentPage = page;
        }
        if (limit) {
          defaultLimit = limit;
        }
        const body = {
          filters,
          limit: defaultLimit,
          page: currentPage,
          sort: { param: "published_at", order: "asc" },
        };
        if (categories) {
          if (typeof categories[0] === "number") {
            filters.push({ key: "termIds", value: categories });
          } else filters.push({ key: "termSlugs", value: categories });
        }
        if (type) {
          filters.push({ key: "postTypeSlugs", value: type });
        }
        const data = await this.$axios.post(
          "/api/v1/cms/post/filterStandard",
          body
        );
        if (data) {
          if (limit) {
            return data.data.data;
          } else {
            return data.data.data.data;
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async $getPageData(slug) {
      try {
        const data = await this.$axios.get(
          "/api/v1/cms/finder/decide?originalTranslation=true&params[]=" + slug
        );
        if (data) {
          return data.data.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async $getCategoriesInGroup(groupSlug) {
      let groupId = null;
      let groupIdArray = [];
      try {
        const data = await this.$axios.post(
          `/api/v1/cms/termType/filterStandard`
        );
        if (data) {
          groupIdArray = data.data.data.filter(
            (group) => group.title === groupSlug
          );
          groupId = groupIdArray[0].id;
        }
      } catch (e) {
        console.log(e);
      }
      if (groupId) {
        try {
          const data = await this.$axios.post(
            `/api/v1/cms/term/filterStandard`,
            {
              filters: [
                {
                  key: "termTypeIds",
                  value: [groupId],
                },
              ],
              page: 1,
              offset: 0,
              limit: 1000,
            }
          );
          if (data) {
            return data.data.data.data.categories;
          }
        } catch (e) {
          console.log(e);
        }
      }
    },

    $renderRecaptcha(id) {
      if (!id) return;

      if (typeof grecaptcha !== "undefined" && this.$recaptcha) {
        grecaptcha.ready(() => {
          try {
            grecaptcha.render(id, {
              sitekey: this.$recaptcha.siteKey,
            });
          } catch (err) {
            console.log(err);
          }
        });
      }
    },
  },
});
